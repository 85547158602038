import React from 'react'
import Contact2 from '../Components/Contact/Contact2';
import Consultation from '../Components/Home/Consultation';
import Hero from '../Components/Contact/Hero';
import Faqs from '../Components/Faq/Faqs';
import WhatappUs from '../Components/Contact/WhatappUs';

const ContactPage = () => {
  return (
   <div>
    <Hero/>
    <Contact2/>
    <WhatappUs/>
    <Consultation/>
   
    <Faqs/>
   </div>
  )
}

export default ContactPage