import React from 'react'
import "./hero.css"
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading'
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
import danish from "../../assets/MeetTheTeam/dcropped2.png"
const Hero = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.4,
      });
    return (
        <div className=' bg-lightpinkl font-poppins text-shadyBlack'>
            <motion.div
             ref={ref}
             initial="hidden"
             animate={inView ? "show" : "hidden"}
             viewport={{ once: true }}
             variants={{
               hidden: {},
               show: {
                 transition: {
                   staggerChildren: 0.15,
                 },
               },
             }}
             className='px-4 md:px-20 py-20 md:py-10'>
                <motion.div
                variants={fadeIn('down', 1)} 
                 className="flex items-center pt-16 pb-8 justify-center">
                    <CurvedUnderlineHeading>
                        Our Team
                    </CurvedUnderlineHeading>
                </motion.div>


                <div class="container1 flex-col pt-2 ">
                    <motion.div
                     variants={fadeIn('right', 1)} 
                     className='flex flex-col-reverse md:flex-row md:justify-between md:border-l-8 border-primary-purple  items-center'>
                        <p className='md:w-4/5 text-sm md:text-xl md:pl-16 md:leading-10 '>
                            <span className='font-semibold'>Danish </span>  is a seasoned education consultant with over five years of experience at a prestigious university in London. His extensive knowledge of university requirements and scholarship opportunities equips him to provide tailored guidance to students. Driven by a desire to make a positive impact, Danish believes in working with multiple leading institutions to maximize student success. His commitment to supporting families through their educational journeys is at the core of our consultancy.
                        </p>
                        <div class="box ">
                            <div class="imgBox ">
                                <img src={danish} alt="" />
                            </div>
                            <div class="content">
                                <h2>Danish

                                    <br />
                                    <span>Co-Founder</span>
                                </h2>
                            </div>
                        </div>

                    </motion.div>
                    {/* <hr className='bg-primary-purple w-full py-0.5 '/> */}
                    <motion.div 
                    variants={fadeIn('left', 1)} 
                    className='flex flex-col md:flex-row md:gap-5  md:border-r-8 border-primary-purple items-center'>
                        <div class="box">
                            <div class="imgBox">
                                <img src="https://image.freepik.com/free-photo/waist-up-portrait-handsome-serious-unshaven-male-keeps-hands-together-dressed-dark-blue-shirt-has-talk-with-interlocutor-stands-against-white-wall-self-confident-man-freelancer_273609-16320.jpg" alt="" />
                            </div>
                            <div class="content">
                                <h2>Sarmad<br />
                                    <span>Co-Founder</span></h2>
                            </div>
                        </div>
                        <p className='md:w-2/3 text-sm md:text-xl md:leading-10'>
                            <span className='font-semibold'>Sarmad</span>  is a researcher and successful entrepreneur, bringing a unique perspective to First Group Consultant. His deep understanding of research and the complexities of the academic system allows him to offer invaluable insights to students, particularly those pursuing advanced degrees. With a focus on fostering innovation and practical skills, Sarmad is dedicated to preparing students for both academic challenges and real-world scenarios.

                        </p>
                    </motion.div>



                </div >

            </motion.div >

        </div >
    )
}

export default Hero
