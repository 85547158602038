import React from 'react'
import { PopupButton } from "react-calendly";
import { HiArrowUpRight } from "react-icons/hi2";
const Book = ({content}) => {
  return (
    <div className=' relative  cursor-pointer scale-105 hover:scale-100 ease-in-out duration-500'>
        <div className='bg-[#A4A3F8] absolute rounded-sm -z-0    hover:-pt-0 pb-2.5 pr-[9.5px] hover:pb-0 hover:pr-0 ease-in-out duration-500w-full'>
         <div className=' px-5 z-10 rounded-sm py-3.5 -my-1 -mx-1 hover:-mx-1 hover:-my-0 hover:bg-[#5354AC] ease-in-out duration-500  bg-[#706FED] '>
         <div className='  flex justify-between text-nowrap  gap-4 items-center'>
         <PopupButton
         className='text-white'
        url="https://calendly.com/firstgroupconsultantuk/30min"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text={content}
      />
         <HiArrowUpRight color='white' size={24}  />
         </div>
      
        
        </div>
        </div>
        </div>
       
       
   
  )
}

export default Book