import React from 'react'
import img1 from '../assets/Footer/x.png.png';
import img3 from '../assets/Footer/Link.png';
import logo from "../assets/Home/logo.png"

const Footer = () => {
     return (
          <div className='flex px-4 md:px-10 items-center bg-lightpinkl border-t-4 border-primary-purple text-shadyBlack justify-between h-[10vh] '>



               <div className='flex items-center justify-center gap-4'>
                    <img src={logo} alt='logo' className='w-10 h-10' />
                    <h1 className='text-xs md:text-lg leading-5 font-medium tracking-wider'>© Copyright 2024 by First Group Consultant</h1>
               </div>



               {/* <div className='flex justify-center items-center py-4 gap-3'>
                    <div>
                         <img className=' ' src={img1} alt='?' />
                    </div>


               </div> */}
          </div>
     )
}

export default Footer