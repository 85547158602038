import React, { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";

const faqData = [
  {
    question: 'How do I start the application process?',
    answer:
      'To begin your application process, simply reach out to us via our contact form, email, or phone. Our team will schedule a free consultation to understand your goals and guide you through the necessary steps.',
  },
  {
    question: 'What documents do I need for my application?',
    answer: (
      <>
        <p>You will typically need the following documents for your application:</p>
        <ul className='py-4 px-6 list-disc'>
          <li>Academic transcripts and certificates</li>
          <li>A valid passport</li>
          <li>Personal statement</li>
          <li>Letters of recommendation</li>
          <li>Curriculum Vitae (CV) or resume</li>
          <li>Proof of English language proficiency (if required)</li>
        </ul>
        <p>
          Our team will provide you with a detailed checklist tailored to your
          chosen program and institution.
        </p>
      </>
    ),
  },
  {
    question: 'Can you assist with scholarship applications?',
    answer:
      'Yes, we offer comprehensive support for scholarship applications. Our team will help you identify suitable scholarships, guide you through the application process, and assist you in preparing compelling personal statements and supporting documents.',
  },
  {
    question: 'How long does the application process take?',
    answer:
      'The duration of the application process can vary based on the university and program. Generally, it can take anywhere from a few weeks to several months. We recommend starting early to ensure you meet all deadlines.',
  },
  {
    question: 'What is the difference between a foundation year, bachelor’s degree, and master’s degree?',
    answer: (
      <>
        <ul className='px-3 py-2 space-y-5 list-disc'>
          <li>
            <strong>Foundation Year:</strong> A preparatory course designed for students who may not meet the direct entry requirements for a bachelor’s program.
          </li>
          <li>
            <strong>Bachelor’s Degree:</strong> An undergraduate program that typically takes three to four years to complete, focusing on a specific field of study.
          </li>
          <li>
            <strong>Master’s Degree:</strong> A postgraduate program that usually takes one to two years to complete, allowing students to specialize further in their field.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'Do you help with visa applications?',
    answer:
      'Absolutely! Our team is well-versed in the visa application process and can provide guidance on the necessary documentation and requirements. We will support you throughout the entire process to ensure a smooth application.',
  },
  {
    question: 'What do your pre-departure orientation sessions cover?',
    answer: (
      <>
        <p>Our pre-departure orientation sessions cover essential topics such as:</p>
        <ul className='py-4 px-6 list-disc'>
          <li>Cultural adjustment and integration</li>
          <li>Academic expectations in the UK</li>
          <li>Practical tips for living in the UK</li>
          <li>Networking opportunities with other students</li>
        </ul>
      </>
    ),
  },
  {
    question: 'How can I contact you if I have more questions?',
    answer: (
      <>
        <p>You can contact us through our website, email at <strong>info@firstgroupconsultant.com</strong>, WhatsApp us at <strong>+44 7922 447784</strong>, or book a call with us. We are here to help!</p>
      </>
    ),
  },
];

const Faqs = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.4,
      });
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div className='md:px-20 px-4 space-y-20 bg-lightpinkl md:pt-32 py-10 font-poppins'>
      <div className='flex justify-center items-center'>
        <CurvedUnderlineHeading>Frequently Asked Questions</CurvedUnderlineHeading>
      </div>

      <div className='space-y-8  md:px-8'>
        {faqData.map((faq, index) => (
          <div key={index}>
            <div className='flex justify-between'>
              <h1 className='text-sm md:text-lg font-semibold tracking-wide'>
                <span className='font-medium'>{index + 1}.</span> {faq.question}
              </h1>
              {openFaq === index ? (
                <FaMinus className='cursor-pointer md:text-xl text-sm' onClick={() => toggleFaq(index)} />
              ) : (
                <FaPlus className='cursor-pointer md:text-xl text-sm' onClick={() => toggleFaq(index)} />
              )}
            </div>

            {/* FAQ Answer with smooth animation */}
            <div
              className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                openFaq === index ? 'max-h-screen' : 'max-h-0'
              }`}
            >
              <div className='p-4 pt-8'>
                <p className='leading-6 md:leading-10 text-xs md:text-xl '>{faq.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
