import student from "../../assets/Home/student.jpg";
import React from "react";
import Book from "../../global/Book";
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
const Hero = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4, 
  });
  return (
    <motion.div 
      ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
    className=" font-poppins   px-4 bg-primary-purple md:px-20 text-shadyBlack pb-32 md:pb-4 flex pt-16  md:justify-center md:items-center ">
      <div className=" hidden absolute  lg:flex top-[150px] lg:pl-0 xl:pl-36 z-50">
      <div className="  w-full    ">
      <Book content="Book A Consultation"/>
      </div>
   
      </div>
     
          
       
      <div className=" container mx-auto  justify-between items-center   md:space-y-5 flex-col md:flex-row flex">
        <motion.div
        variants={fadeIn('right', 1)} 
         className=" px-2  space-y-2 md:w-3/5">

         
          <div className="space-y-4">
            <p className="text-xl   font-medium md:text-xl  bg-darkpuple text-white w-fit p-1.5 rounded-md  ">
            Welcome To  
            </p>
            <p className="text-2xl   font-medium md:text-6xl tracking ">
               First Group Consultant
            </p>
            
          </div>
          <div className="">
            <p className="text-xl text-shadyBlack md:w-4/5 py-4 font-semibold tracking-widest ">
             
            Shape your future in the UK 

            </p>
            <p className="text-md md:w-4/5 ">
             
            At<span className="font-bold"> First Group Consultant</span> , we specialize in helping students from all walks of life pursue their dream of studying in the UK. Whether you’re an international student or a UK-based student, we provide personalized guidance and support to help you secure a place in one of the UK’s top universities or colleges.

             </p>
             
          </div>
          <div className="pl-2 pt-6 lg:hidden">
          <Book content="Book A Consultation"/>
          </div>
          
           
        </motion.div>
        <motion.div
        variants={fadeIn('left', 1)} 
         className="md:flex md:w-2/5 hidden rounded-bg    md:pt-10 overflow-hidden relative justify-center items-center">
           <div className="bg-darkpuple w-[400px] shadow-md -bottom-2.5  rounded-full  absolute h-[400px] z-10"></div>
           <div className=" z-20    w-fit ">
              <img
                src={student}
                className="  relative max-w-[800px] "
                alt="?"
              />
            
            </div>
        </motion.div>
        
      </div>
      
         
    </motion.div>
  );
};

export default Hero;
