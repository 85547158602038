import React, { useEffect } from 'react'
import Hero from '../Components/Services/Hero'
import ServiceSection from '../Components/Services/ServiceSection'
import Consultation from '../Components/Home/Consultation'
import RightChoice from '../Components/Services/RightChoice'

const Services = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
     <Hero/>
     <ServiceSection/>
     <RightChoice/>
     <Consultation/>
    </div>
  )
}

export default Services
