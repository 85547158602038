import React from 'react'

const CurvedUnderlineHeading = ({ children, className = '' }) => {
    return (
      <div className="relative inline-block">
        <h1 className={`text-4xl md:text-5xl text-center md:text-left tracking-wider font-semibold ${className}`}>
          {children}
        </h1>
        <div 
          className="absolute -bottom-3 left-0 w-full h-4 bg-no-repeat bg-left bg-[length:100%_16px]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpath d='M0,0 Q50,100 100,0' stroke='%23847fd2' fill='none' stroke-width='8'/%3E%3C/svg%3E")`
          }}
        ></div>
      </div>
    );
  };

export default CurvedUnderlineHeading
