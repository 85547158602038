import React from 'react'
import Contact2 from '../Components/Contact/Contact2';

const Contact = () => {
  return (
    <div>
  <Contact2/>
    </div>
  )
}

export default Contact