import React from 'react'
import aboutpic from '../../assets/About/about1.png'
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
const Hero = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
  return (
    <div className='bg-primary-purple  pt-8'>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        className='flex container overflow-x-hidden mx-auto flex-col  md:flex-row justify-center md:justify-between items-center px-4 lg:px-20  md:pt-20'>
        <motion.div
        variants={fadeIn('right', 1)} 
         className='px-4 w-full  space-y-3 flex flex-col gap-4'>
          <p className="text-xl   font-medium md:text-xl  bg-darkpuple/30 w-fit p-1.5 rounded-md  ">
            Our Story
          </p>
          <h1 className=' text-3xl md:text-5xl  font-medium'>First Group Consultant</h1>
          <h1 className=' text-xl   '>Empowering Your Journey to Success in the UK </h1>
        </motion.div>
        <motion.div
        variants={fadeIn('left', 1)} 
         className=' flex relative w-full md:overflow-visible overflow-hidden max-w-[800px]'>
          <div className='absolute hidden xl:block bg-darkpuple  w-[250px] md:w-[350px] rounded-full shadow-2xl h-[250px] md:h-[350px] -bottom-8 md:bottom-0 z-10 -right-4 md:right-auto md:left-1/2'></div>
          <img className=' z-20 ' loading='lazy' alt='?' src={aboutpic} />
        </motion.div>
      </motion.div>
    </div>
  )
}

export default Hero
