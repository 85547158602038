import React from 'react'
import potential from "../../assets/About/educationcutie.svg"
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
const Education = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.4, 
      });
  return (
    <div className='bg-lightpinkl '>
       <motion.div
       ref={ref}
       initial="hidden"
       animate={inView ? "show" : "hidden"}
       viewport={{ once: true }}
       variants={{
         hidden: {},
         show: {
           transition: {
             staggerChildren: 0.15,
           },
         },
       }}
        className=" container mx-auto overflow-x-hidden   px-4 lg:px-20 text-shadyBlack font-poppins py-8 flex flex-col md:flex-row items-center gap-10 justify-center">
          <motion.div 
           variants={fadeIn('right', 1)} 
           className=" md:w-1/2">
            <img src={potential} className="w-full" alt="img" />

          </motion.div>
          <motion.div 
           variants={fadeIn('left', 1)} 
           className=" md:w-1/2 py-8 space-y-10 text-left ">
            <p className="text-lg leading-8 ">
              At  <span className="font-semibold">First Group Consultant</span>,
              we believe that every student has the potential to achieve
              greatness. Based in the heart of London, our mission is to guide
              students from around the world and within the UK & EU as they embark
              on their educational journey. We specialize in helping students gain
              admission to some of the UK’s and EU’s top universities and
              colleges, focusing exclusively on <span className="font-semibold"> foundation year, bachelor’s, master’s
              </span> and <span className="font-semibold">research </span>{" "}
              degree programs.
            </p>
            <p className="text-lg leading-8">

              Whether you’re an
               <span className="font-semibold"> international student</span> looking
              to study in the UK or EU or a
              <span className="font-semibold"> UK-based student</span> seeking
              expert guidance, our team is here to provide the support you need at
              every stage of the process. From choosing the right course to
              navigating visa applications, we are with you every step of the way.
            </p>
          </motion.div>
        </motion.div>
    </div>
  )
}

export default Education
