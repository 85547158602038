import React from 'react'
import whyUsImg from '../../assets/Home/two-person.png'
import { BookOpen, Users, Compass, Award } from 'lucide-react';
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";

const WhyUs = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.4, 
      });
    const reasons = [
        {
            "icon": <BookOpen className="w-12 h-12 text-blue-600" />,
            "title": "UK & EU Based Degrees",
            "description": "We work exclusively with universities and colleges in the UK and EU to offer you the best educational opportunities."
        },
        {
            "icon": <Users className="w-12 h-12 text-green-600" />,
            "title": "Tailored Guidance",
            "description": "We can assist you if you are an International student looking to get admission into one of the top education institute in the UK or if you are already in the UK and are exploring options to start or continue your educational journey"
        },
        {
            "icon": <Compass className="w-12 h-12 text-purple-600" />,
            "title": "End-to-End Support",
            "description": "From choosing the right institution to visa applications, we guide you through every step."
        },
        {
            "icon": <Award className="w-12 h-12 text-yellow-600" />,
            "title": "Expert Consultants",
            "description": "Our experienced team ensures that your application stands out, increasing your chances of success."
        }
    ]

    return (
        <div
       
         className='font-poppins bg-lightpinkl text-shadyBlack '>
            <motion.div
             ref={ref}
             initial="hidden"
             animate={inView ? "show" : "hidden"}
             viewport={{ once: true }}
             variants={{
               hidden: {},
               show: {
                 transition: {
                   staggerChildren: 0.15,
                 },
               },
             }} className='  mx-auto container  py-20 px-4  overflow-x-hidden  lg:px-20'>
                {/* <h1 className='text-base md:text-5xl font-semibold rounded-md  tracking-wider '>Why We’re the Right Choice for You?</h1> */}
                <div className='flex flex-col  gap-8 items-center '>
                <motion.div 
                 variants={fadeIn('down', 1)} 
                className="flex items-center justify-center  ">
                    <CurvedUnderlineHeading>
                        Why we’re the Right Choice for you?
                    </CurvedUnderlineHeading>

                </motion.div>
                <div className='flex md:flex-row flex-col gap-10 w-full'>
                    <motion.div
                     variants={fadeIn('right', 1)} 
                      className='pt-16 max-w-xl w-full '>
                        <img className='' src={whyUsImg} />
                    </motion.div>

                    <motion.div
                     variants={fadeIn('left', 1)} 
                     className="grid grid-cols-1  py-10   gap-8">
                        {reasons.map((reason, index) => (
                            <motion.div
                                key={index}
                                variants={fadeIn('up', index * 0.4)}
                                className="bg-primary-purple/10 w-full group p-8 rounded-lg cursor-pointer shadow-lg border-2 border-darkpuple/10 hover:border-darkpuple/30 transition-all duration-300 hover:shadow-xl hover:scale-105 flex flex-col items-center relative overflow-hidden group"
                            >
                                <div className="absolute inset-0 bg-primary-purple/40 transform translate-y-full transition-transform duration-500 ease-in-out group-hover:translate-y-0"></div>
                                <div className="relative z-10 flex gap-10 items-center">
                                    <div className="mb-2 ">{reason.icon}</div>
                                    <div className='flex flex-col items-start'>
                                        <h3 className="text-lg font-semibold text-start md:text-center mb-1">{reason.title}</h3>
                                        <p className="text-sm text-gray-600 ">{reason.description}</p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                        
                    </motion.div>
                </div>
                </div>
            </motion.div>

        </div>
    )
}

export default WhyUs
