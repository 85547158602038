import React from "react";
import Hero from "../Components/AboutUs/Hero";
import goal from "../assets/About/goals.png";
import book from "../assets/About/bookstack.jpg"
import potential from "../assets/About/educationcutie.svg"
import ContactForm from "../Components/AboutUs/ContactForm";
import CurvedUnderlineHeading from "../global/CurvedUnderlineHeading";
import Contact from "./Contact";
import Education from "../Components/AboutUs/Education";
import Story from "../Components/AboutUs/Story";
import Vision from "../Components/AboutUs/Vision";
import Contact2 from "../Components/Contact/Contact2";
const About = () => {
  return (
    <div className=" font-poppins ">
      <Hero />
      <Education />
      <Story />
      <Vision />
      <Contact2 />
    </div>
  );
};

export default About;
