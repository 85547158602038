import React from 'react'
import agreement from "../../assets/Services/uniadd.svg"
import doumentation from "../../assets/Services/documenta.svg"
import visaprep from "../../assets/Services/visaa.svg"
import predeparture from "../../assets/Services/predep.svg"
import ongoinmail from "../../assets/Services/supporta.svg"
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
import Service from './Service'

const ServiceSection = () => {
  
    // Create a reusable function that will handle the inView logic for each section
    // const renderServiceSection = (image, title, content, direction) => {
    //     const { ref, inView } = useInView({
    //         triggerOnce: true, // Ensure the animation triggers only once
    //         threshold: 0.3,    // Adjust threshold for when to trigger the animation (0.3 means when 30% of the section is in view)
    //     });

    //     return (
    //         <motion.div
    //             ref={ref} // Use the ref from useInView
    //             initial="hidden"
    //             animate={inView ? "show" : "hidden"}
    //             variants={{
    //                 hidden: {},
    //                 show: {
    //                     transition: {
    //                         staggerChildren: 0.2,
    //                     },
    //                 },
    //             }}
    //             className={`flex md:flex-row ${direction === 'reverse' ? 'flex-col-reverse' : 'flex-col'} justify-between py-20 gap-10`}
    //         >
    //             {/* Image Section */}
    //             <motion.div 
    //                 variants={fadeIn(direction === 'reverse' ? 'right' : 'left', 1)}
    //                 className="md:w-1/3 flex justify-center items-center"
    //             >
    //                 <img src={image} className="w-full rounded-lg" alt={title} />
    //             </motion.div>

    //             {/* Text Section */}
    //             <motion.div
    //                 variants={fadeIn(direction === 'reverse' ? 'left' : 'right', 1)}
    //                 className="flex flex-col gap-4 justify-center items-center md:w-2/3"
    //             >
    //                 <h1 className="text-xl md:text-4xl w-fit text-center bg-darkpuple/10 p-2 rounded-lg font-semibold tracking-widest border-2 shadow-md border-darkerPurple/10">
    //                     {title}
    //                 </h1>
    //                 <p className="max-w-4xl pt-8">{content.description}</p>
    //                 <ul className="list-disc list-inside flex flex-col gap-6">
    //                     {content.points.map((point, index) => (
    //                         <li key={index} className="flex flex-col">
    //                             <h2 className="font-semibold text-xl">{point.title}</h2>
    //                             <p>{point.text}</p>
    //                         </li>
    //                     ))}
    //                 </ul>
    //             </motion.div>
    //         </motion.div>
    //     );
    // };

    // Content for each section
    const serviceSections = [
        {
            image: agreement,
            title: "University & College Admissions",
            content: {
                description: "Navigating the admissions process can be daunting, but our experienced team is here to guide you every step of the way. We assist you in identifying suitable institutions that align with your academic goals and personal strengths. Our services include",
                points: [
                    {
                        title: "Personalized Consultation",
                        text: "We work closely with you to understand your aspirations and preferences, helping you make informed choices about your academic futures."
                    },
                    {
                        title: "Application Support",
                        text: "From filling out application forms to preparing necessary documentation, we ensure that you are well-prepared to present your best selves to universities and colleges."
                    }
                ]
            },
            direction: "normal"
        },
        {
            image: doumentation,
            title: "Document Preparation & Review",
            content: {
                description: "Having the right documentation is crucial for a successful application. We provide comprehensive support in preparing and reviewing essential documents, including",
                points: [
                    {
                        title: "Transcripts",
                        text: "We guide you in obtaining and submitting your academic transcripts accurately."
                    },
                    {
                        title: "Recommendation Letters",
                        text: "Our team offers advice on how to choose the right referees and provides tips for writing compelling recommendation letters."
                    },
                    {
                        title: "Personal Statements",
                        text: "We assist you in crafting impactful personal statements that highlight your strengths, experiences, and aspirations, making your applications stand out."
                    }
                ]
            },
            direction: "reverse"
        },
        {
            image: visaprep,
            title: "Visa Assistance",
            content: {
                description: "Understanding the visa application process can be complex. Our team is dedicated to simplifying this process for you. Our visa assistance services include",
                points: [
                    {
                        title: "Guidance on Visa Types",
                        text: "We provide information on the different visa options available for studying in the UK & EU."
                    },
                    {
                        title: "Application Support",
                        text: "Our experts help you prepare and submit your visa applications, ensuring all requirements are met for a smooth application process."
                    },
                    {
                        title: "Pre-Interview Preparation",
                        text: "We offer mock interviews and guidance on how to effectively communicate during visa interviews."
                    }
                ]
            },
            direction: "normal"
        },
        {
            image: predeparture,
            title: "Pre-Departure Orientation",
            content: {
                description: "Preparing for life in a new country is essential for success. Our pre-departure orientation sessions equip you with the knowledge and skills you need to thrive in the UK & EU. These sessions cover",
                points: [
                    {
                        title: "Cultural Awareness",
                        text: "We familiarize you with cultural norms, traditions, and customs in the UK to help you adjust more easily"
                    },
                    {
                        title: "Academic Expectations",
                        text: "You learn about the UK & EU education system, including teaching methods, assessment styles, and academic integrity."
                    },
                    {
                        title: "Practical Information",
                        text: "We provide valuable tips on housing, transportation, healthcare, and other essential aspects of living in the UK & EU."
                    }
                ]
            },
            direction: "reverse"
        },
        {
            image: ongoinmail,
            title: "Ongoing Support",
            content: {
                description: "Our commitment to you doesn’t end once you arrive in the UK or EU.",
                points: [
                    {
                        title: "Academic Guidance",
                        text: "We provide resources and advice on managing coursework, study techniques, and time management."
                    },
                    {
                        title: "Career Development",
                        text: "Our team offers support for internships, job placements, and networking opportunities, helping you build your professional profiles."
                    },
                    {
                        title: "Wellness Support",
                        text: "We understand the challenges of studying abroad, and we are here to provide guidance on mental health and well-being."
                    }
                ]
            },
            direction: "normal"
        }
    ];

    return (
        <div className="bg-lightpinkl font-poppins">
            <div className="py-2 px-4 md:px-20">
                {serviceSections.map((section, index) =>
                
                Service(
                        section.image,
                        section.title,
                        section.content,
                        section.direction
                    )
                )}
            </div>
        </div>
    );
};

export default ServiceSection;
