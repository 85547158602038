import React from 'react'

import missionvison from "../../assets/About/missionandvison.svg"
import { motion } from "framer-motion";
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading'
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
const Vision = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2, 
      });
  return (
    <div className='bg-lightpinkl'>
      <motion.div
       ref={ref}
       initial="hidden"
       animate={inView ? "show" : "hidden"}
       viewport={{ once: true }}
       variants={{
         hidden: {},
         show: {
           transition: {
             staggerChildren: 0.15,
           },
         },
       }}
       className=" px-4 lg:px-20 overflow-x-hidden py-20 space-y-10 ">
          <motion.div
           variants={fadeIn('down', 1)} 
            className="flex items-center pb-8 justify-center">
            <CurvedUnderlineHeading>
              Mission & Vision
            </CurvedUnderlineHeading>
          </motion.div>
          <div
           className="  flex flex-col md:flex-row md:gap-12 items-center justify-between">

            <motion.div
             variants={fadeIn('right', 1)}  className="md:w-2/3 flex-col space-y-10">
              <div className="space-y-5">
                <h1 className="font-semibold text-3xl tracking-wider  ">
                  Our Mission
                </h1>
                <p className="text-lg leading-8 ">
                  To empower both
                  <span className="font-medium text-darkerPurple"> international </span> and
                  <span className="font-medium text-darkerPurple"> UK-based student</span> by
                  providing personalized educational consulting services,
                  fostering connections with top UK & EU institutions, and guiding
                  them toward achieving academic and personal success.
                </p>
              </div>
              <div className="space-y-5">
                <h1 className="font-semibold text-3xl tracking-wider ">
                  Our Vision
                </h1>
                <p className="text-lg leading-8 ">
                  To become the leading education consultancy for UK & EU bound
                  students, transforming lives through expert guidance, support,
                  and networking, ensuring that every student thrives in their
                  chosen field.
                </p>
              </div>
            </motion.div>
            <motion.div 
             variants={fadeIn('left', 1)} 
             className="md:w-1/2">
              <img src={missionvison} alt="mission" />
            </motion.div>
          </div>

        </motion.div>
    </div>
  )
}

export default Vision
