import React from 'react'
import whyuspersin from "../../assets/Services/chooseb.svg"
const RightChoice = () => {
    return (
        <div className='bg-lightpinkl font-poppins'>
            <div className='px-4 md:px-20 '>
                {/* <div className='flex items-center justify-center pb-8 '>
            <h1 className='text-4xl w-fit text-center bg-darkpuple/10 p-2 rounded-lg  font-semibold tracking-wider border-2 shadow-lg  border-darkerPurple/10'>University & College Admissions</h1>
            </div> */}
                <div className='flex md:flex-row flex-col items-center justify-between'>
                   
                    <div className='flex flex-col gap-4 md:w-2/3'>
                    <div className=' space-y-5 pt-6'>

            <h1 className='border-l-8 border-darkpuple text-2xl md:text-5xl font-medium tracking-widest z-10 '><span className='border-l-6 border-darkpuple pr-6'></span>How we help you succeed
            </h1>
          </div>
            
                        
                        <ul className="list-disc pt-12 list-inside text-black">
        <li>We tailor our services to suit the unique needs of both international and UK and EU-based you.</li>
        <li>Our consultants provide step-by-step support throughout the application and visa processes.</li>
        <li>We are committed to helping you succeed, both academically and personally.</li>
      </ul>
                    </div>
                    <div className='md:w-1/3    flex flex-col  justify-evenly items-end'>
                        <img src={whyuspersin} className='w-full  rounded-lg ' alt='agreement' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default RightChoice
