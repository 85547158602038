import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";

const WhatappUs = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.4,
      });
  // Your WhatsApp number in international format (without '+' or spaces)
  const whatsappNumber = '447922447784';
  const message = 'Hello, I would like to inquire about your services.';

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

  return (
    <motion.div 
    ref={ref}
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
    className="flex flex-col items-center justify-center font-poppins space-y-8 py-20 bg-lightpinkl">
      <motion.h1
      variants={fadeIn('down', 0.7)} 
       className="text-2xl font-medium">For quick inquiries or immediate assistance, feel free to text us on WhatsApp. We’re here to help!
      </motion.h1>
      <motion.p 
      variants={fadeIn('down', 0.9)} 
      className='text-lg'>WhatsApp Number: <span className='font-semibold'>+44 7922 447784</span>
      </motion.p>

      <motion.a
      variants={fadeIn('down', 1)} 
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center bg-customBlue text-white px-4 py-3 rounded-lg shadow-lg hover:bg-customBlue/80 transition duration-300 ease-in-out"
      >
        <FaWhatsapp className="text-2xl mr-2" />
        <span className="text-lg">Chat with us on WhatsApp</span>
      </motion.a>
    </motion.div>
  );
};

export default WhatappUs;
