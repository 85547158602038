import React,{useState} from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const Menu = ({setMainToggle}) => {
const [toggle,setToggle] = useState(false)
const closeMobNav=()=>{
  setMainToggle(false)
  setToggle(false)
}
  return (
    <div 
    className=" md:hidden bg-opacity-100 z-30 bg-white min-h-[80vh] absolute top-16 bottom-0 left-0 w-full ">
   
    <ul  className=' py-10 px-6 flex flex-col space-y-11 item'>
    <NavLink onClick={closeMobNav} to={'/'} >  <div  className='border-b-2 border-black/70 py-3 px-6 shadow-lg rounded-lg'>
          <li className=' font-semibold text-2xl text-center tracking-wider'>
         Home  
          </li>
          </div></NavLink>
          <NavLink onClick={closeMobNav} to={'/services'} >  <div  className='border-b-2 border-black/70 py-3 px-6 shadow-lg rounded-lg'>
          <li className=' font-semibold text-2xl text-center tracking-wider'>
         Services  
          </li>
          </div></NavLink>
          
           <div  onClick={()=>{setToggle(!toggle)}} className=' flex items-center justify-center gap-6 border-b-2 border-black/70 text-center py-3 px-6 shadow-lg rounded-lg'>
          <li className=' font-semibold text-2xl tracking-wider'>
          About
          </li>
           {
            toggle? <FaCaretUp  size={24} />:
           <FaCaretDown size={24}/>
          } 
          </div>
           {
             toggle &&  <div className='space-y-11 flex flex-col'>
               <NavLink onClick={closeMobNav} to={'/about'}>  <div  className='border-b-2 border-black/70 text-center py-3 px-6 shadow-lg rounded-lg'>
          <li className=' font-semibold text-2xl tracking-wider'>
          Our Story
          </li>
          </div></NavLink>
         
          <NavLink onClick={closeMobNav} to={'/team'}>  <div  className='border-b-2 border-black/70 text-center py-3 px-6 shadow-lg rounded-lg'>
          <li className=' font-semibold text-2xl tracking-wider'>
          Our Team
          </li>
          </div></NavLink>

             </div>
           }
         
         <NavLink onClick={closeMobNav}  to={'/faq'}>  <div  className='border-b-2 border-black/70 text-center py-3 px-6 shadow-lg rounded-lg'>
          <li className=' font-semibold text-2xl tracking-wider'>
          Faq's
          </li>
          </div></NavLink>
          <NavLink onClick={closeMobNav}  to={'/contact'}>  <div  className='border-b-2 border-black/70 text-center py-3 px-6 shadow-lg rounded-lg'>
          <li className=' font-semibold text-2xl tracking-wider'>
          Contact
          </li>
          </div></NavLink>

    </ul>
    
    </div>
  )
}

export default Menu