import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import Menu from "../global/Menu";
import { NavLink } from "react-router-dom";
import logo from "../assets/Home/logo.png"


const Nav = () => {
  const [toggle, setToggle] = useState(false);
  const [click,setClick] = useState(false)
  return (
    <div className="   md:absolute text-sm md:text-xl z-10  top-0 w-full px-4 md:px-20 font-poppins py-5  flex justify-between items-center tracking-wide ">
    
    <NavLink to={'/'}> <h1 className="text-shadyblack font-semibold text-sm md:text-xl tracking-widest flex items-center justify-center gap-4 logo ">
         
         <img src={logo} alt="logo" className="md:w-16 w-10 h-10 md:h-16"/> <span className="lg:text-lg text-sm">First Group Consultant</span>
       </h1></NavLink>
     

      <div className="hidden md:block">
        <ul className="flex gap-10">
          <NavLink to={'/'}> 
            <li className="cursor-pointer font-semibold hover:text-darkerPurple      duration-200 ">
              Home
            </li>
          </NavLink>
          <NavLink to={'/services'}>
            <li className="cursor-pointer font-semibold hover:text-darkerPurple   duration-200  ">
            Services
            </li>
          </NavLink>
          <div onMouseEnter={()=>{setClick(!click)}} onMouseLeave={()=>{setClick(!click)}} className="relative ">
            <li      className="cursor-pointer font-semibold hover:text-darkerPurple   duration-200  ">
              About
            </li>
            {click?<div className=" flex-col absolute top-7 px-4 w-[150px]  bg-white/70 py-1 rounded-lg -ml-8"> 
            <NavLink  to={'/about'}>  <h1 className="cursor-pointer font-semibold hover:text-darkerPurple py-1  duration-200  ">Our Story</h1></NavLink> 
             <NavLink  to={'/team'}>  <h1 className="cursor-pointer font-semibold hover:text-darkerPurple py-1  duration-200  ">Our Team</h1></NavLink> 
            </div>: null}
          </div>
          <NavLink to={'/faq'} >
            <li className="cursor-pointer font-semibold hover:text-darkerPurple   duration-200  ">
              FAQs
            </li>
          </NavLink>
          <NavLink to={'/contact'} >
            <li className="cursor-pointer font-semibold hover:text-darkerPurple   duration-200  ">
              Contact
            </li>
          </NavLink>
        </ul>
        <div></div>
      </div>

      <div className="md:hidden block">
        {toggle ? (
          <div>
            <IoCloseSharp
              size={28}
              onClick={() => {
                setToggle(!toggle);
              }}
            />
          </div>
        ) : (
          <div>
            <FaBars
              size={28}
              onClick={() => {
                setToggle(!toggle);
              }}
            />
          </div>
        )}
      </div>
      {toggle ? <Menu setMainToggle={setToggle} /> : null}
    </div>
  );
};

export default Nav;
