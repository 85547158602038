import React from 'react'
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading'
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
const Comitment = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.4,
      });
    return (
        <div className=' bg-lightpinkl '>
            <motion.div
             ref={ref}
             initial="hidden"
             animate={inView ? "show" : "hidden"}
             viewport={{ once: true }}
             variants={{
               hidden: {},
               show: {
                 transition: {
                   staggerChildren: 0.15,
                 },
               },
             }}
             className='py-10 px-20 font-poppins tracking-wider text-shadyBlack '>
                <div className=' space-y-16'>
                    <motion.div
                    variants={fadeIn('right', 1)}  className='flex flex-col items-center justify-center space-y-8'>
                        <div className="flex items-center pt-16 pb-8 justify-center">
                            <CurvedUnderlineHeading>
                                What We Believe In
                            </CurvedUnderlineHeading>
                        </div>
                        <p className='text-lg leading-8 w-full max-w-6xl text-center'>At First Group Consultant, students are at the heart of everything we do. We believe that every student’s journey is unique and deserves a personalized approach. Your success is our success, and we are committed to helping you achieve your educational and career goals.</p>
                    </motion.div>
                    <motion.div
                     variants={fadeIn('left', 1)}
                     className='flex flex-col items-center justify-center space-y-8'>
                        <div className="flex items-center pt-16 pb-8 justify-center">
                            <CurvedUnderlineHeading>
                                Our Commitment To You
                            </CurvedUnderlineHeading>
                        </div>
                        <p className='text-lg leading-8 w-full max-w-6xl text-center'>We are dedicated to building lasting relationships with our students and partners. By working with First Group Consultant, you’re not just choosing a consultancy—you’re choosing a team that’s invested in your future. We strive to ensure that every student is equipped with the tools, knowledge, and confidence to thrive in the UK’s & EU’s academic environment and beyond.

                        </p>
                    </motion.div>
                </div>


            </motion.div>
        </div>
    )
}

export default Comitment
