import React from 'react'
import { HiArrowUpRight } from "react-icons/hi2";
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
const Consultation = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
  return (
    <div className='bg-darkpuple  font-poppins'>
      <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
       className=' px-4 overflow-x-hidden  py-12 md:p-20 w-full mx-auto container bgcircles text-white flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:justify-between'>
        <motion.div 
         variants={fadeIn('right', 1)} 
        className='flex flex-col  gap-3 w-full md:w-1/2'>
          <h1 className='bg-white/10 border text-sm md:text-xl border-darkpuple shadow-lg w-fit p-2.5 rounded-md backdrop-blur-xl text-nowrap'>Get Started Today!</h1>
          <h2 className=' text-lg md:text-3xl lg:text-4xl xl:text-5xl font-semibold text-nowrap drop-shadow-lg'>Take the first step towards your future</h2>
          <p className='w-full text-sm md:text-xl'>with our expert team and discover how we can help you navigate the exciting journey of studying in the UK.</p>
        </motion.div>
        <div className=' w-full lg:w-1/2   flex items-center justify-start lg:justify-center'>
          <div className=' hidden lg:flex items-center gap-6 '>

            <motion.div
             variants={fadeIn('left', 1)} 
             className='bg-blue-100 relative  cursor-pointer scale-105 hover:scale-100 ease-in-out duration-500'>
              <div className='bg-[#A4A3F8] absolute rounded-sm -z-0    hover:-pt-0 pb-2.5 pr-[9.5px] hover:pb-0 hover:pr-0 ease-in-out duration-500w-full'>
                <div className=' px-5 z-10 rounded-sm py-3.5 -my-1 -mx-1 hover:-mx-1 hover:-my-0 hover:bg-[#5354AC] ease-in-out duration-500  bg-[#706FED] '>
                  <div className='  flex justify-between  gap-4 items-center'>
                    <h1 className='text-white text-nowrap text-md'>Book A Free Consultation</h1>
                    <HiArrowUpRight color='white' size={24} />
                  </div>


                </div>
              </div>
            </motion.div>


          </div>
          <button className='bg-darkerPurple mt-4 flex lg:hidden border border-darkpuple shadow-lg w-fit p-2.5 text-nowrap rounded-md backdrop-blur-xl'>First Free Consultation</button>
        </div>

      </motion.div>

    </div>
  )
}

export default Consultation
