import React from 'react'
import Faqs from '../Components/Faq/Faqs'

const Faq = () => {
  return (
    <div>
<Faqs/>
    </div>
  )
}

export default Faq
