import React from 'react'
import "../Home/service.css"
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";

const Service = (image, title, content, direction) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensure the animation triggers only once
    //threshold: 0.3,    // Adjust threshold for when to trigger the animation (0.3 means when 30% of the section is in view)
});

return (
    <motion.div
        ref={ref} // Use the ref from useInView
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        variants={{
            hidden: {},
            show: {
                transition: {
                    staggerChildren: 0.2,
                },
            },
        }}
        className={`flex md:flex-row ${direction === 'reverse' ? 'flex-col-reverse md:flex-row-reverse' : 'flex-col md:flex-row gap-10'} justify-between py-20 `}
    >
        {/* Image Section */}
        <motion.div 
            variants={fadeIn(direction === 'reverse' ? 'right' : 'left', 1)}
            className="md:w-1/3 flex justify-center items-center"
        >
            <img src={image} className="w-full rounded-lg" alt={title} />
        </motion.div>

        {/* Text Section */}
        <motion.div
            variants={fadeIn(direction === 'reverse' ? 'left' : 'right', 1)}
            className="flex flex-col gap-4 justify-center items-start md:w-2/3"
        >
            <h1 className="text-xl md:text-4xl w-fit text-center bg-darkpuple/10 p-2 rounded-lg font-semibold tracking-widest border-2 shadow-md border-darkerPurple/10">
                {title}
            </h1>
            <p className="max-w-4xl pt-8">{content.description}</p>
            <ul className="list-disc list-inside flex flex-col gap-6">
                {content.points.map((point, index) => (
                    <li key={index} className="flex flex-col">
                        <h2 className="font-semibold text-xl">{point.title}</h2>
                        <p>{point.text}</p>
                    </li>
                ))}
            </ul>
        </motion.div>
    </motion.div>
);
}

export default Service