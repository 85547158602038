import React from "react";
import Home from "./pages/Home";
import Nav from "./global/Nav";
import Team from "./pages/Team";
import { Routes,Route } from "react-router-dom";
import About from "./pages/About";
import ContactPage from "./pages/ContactPage";
import Footer from "./global/Footer";
import Services from "./pages/Services";
import Faq from "./pages/Faq";
import ChatBox from "./Components/Home/ChatBox";
import WhatsappChat from "./Components/Home/WhatsappChat";

function App() {
  return (
    <div className="App">
     <Nav/>
     <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/team" element={<Team/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/contact" element={<ContactPage/>} />
      <Route path="/services" element={<Services/>} />
      <Route path ="/faq" element={<Faq/>}/>
     </Routes>
    <WhatsappChat/>
   <Footer/>
    </div>
  );
}

export default App;
