
import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import ChatBox from './ChatBox';

const WhatsappChat = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);

    const toggleChat = () => {
      setIsChatOpen(!isChatOpen);
    };
  
    return (
      <div className="relative">
        <div
          onClick={toggleChat}
          className="fixed bottom-5 right-5 bg-green-500 rounded-full p-3 cursor-pointer shadow-lg hover:shadow-xl transition-shadow duration-300 z-50"
        >
          <FaWhatsapp size={30} color="white" />
        </div>
        {isChatOpen && <ChatBox onClose={toggleChat} />}
      </div>
    );
  };


export default WhatsappChat
