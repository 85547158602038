import React, { useEffect, useState } from 'react';
import './testimonial.css';
import student1 from '../../assets/Testimonials/testimonial1-removebg-preview.png'
import student2 from '../../assets/Testimonials/testimonial2-removebg-preview.png'
import student3 from '../../assets/Testimonials/testimonial3-removebg-preview.png'
import student4 from '../../assets/Testimonials/testimonial4-removebg-preview.png'
import student5 from '../../assets/Testimonials/testimonial5-removebg-preview.png'
import student6 from '../../assets/Testimonials/testimonial6.jpg'
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading';
import { motion } from 'framer-motion';
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";

const StudentTestimonials = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const students = [
    {
      image: student1, // Replace with actual image paths or URLs
      name: 'Franklyn',
      testimonial:
        "I am Franklyn Chidiebere Iloakasia I am writing to express my heartfelt gratitude for your exceptional guidance and support throughout my journey to pursue higher education in the UK from Nigeria. Your expertise and dedication have been instrumental in making my dreams a reality       From the initial consultation to the visa application process, your team demonstrated unparalleled professionalism, knowledge, and compassion. Your unwavering commitment to ensuring my successful transition to the UK has been truly remarkable.I am deeply thankful for: - Your thorough understanding of the UK education system and requirements - Your expert advice on course and university selection - Your meticulous assistance with document preparation and submission - Your continuous encouragement and reassurance throughout the process Your services have exceeded my expectations, and I wholeheartedly recommend First Group Consultant to anyone seeking to study abroad. You truly are the best! Thank you once again for your outstanding support. I am forever grateful. Best regards",
    },
    {
      image: student2,
      name: 'Gloria',
      testimonial:
        'I am incredibly grateful to First Group Consultant and  Danish Ali for their exceptional support during my admission process to Regent College. Danish guided me through my English test with patience and expertise, making the entire experience seamless. His dedication and encouragement were invaluable, and thanks to his efforts, I am now a proud student at Regent College. I highly recommend First Group Consultant for anyone seeking guidance in their educational journey!',
    },
    {
      image: student3,
      name: 'Faruk',
      testimonial:
        'I cannot thank Danish enough for his incredible support and guidance throughout my journey to studying in the UK. From our very first consultation at First Group Consultants, Danish was attentive, knowledgeable, and always available to answer my questions. His expert advice helped me navigate the complex application process with ease and confidence. Thanks to Danish, I was able to secure the right opportunities and make informed decisions about my studies. I highly recommend Danish and First Group Consultants to anyone seeking to study abroad.',
    },
    {
      image: student4,
      name: 'Gurleen',
      testimonial:
        'During my interview, when I was about to move to the United Kingdom from India, Mr. Danish helped me by providing information regarding Regent College, Holborn Campus, London, in which he told me about campus which greatly helped me in clearing interview process for getting admission in the College. Furthermore, Mr Danish helped in my file processing and my overall experience was very good with Mr. Danish and first group consultant , the process was very good and nothing bothered, made me worried, his expertise helped me a lot',
    },
    {
      image: student5,
      name: 'Parbhjot',
      testimonial:
        'I am incredibly grateful to First Group Consultant and  Danish Ali for their exceptional support during my admission process to Regent College. Danish guided me through my English test with patience and expertise, making the entire experience seamless. His dedication and encouragement were invaluable, and thanks to his efforts, I am now a proud student at Regent College. I highly recommend First Group Consultant for anyone seeking guidance in their educational journey!',
    },
    {
      image: student6,
      name: 'Vijeta',
      testimonial:
        'I had an incredible experience with First Group Consultant and Mr. Danish. Their professionalism and personalised approach, made the entire my admission process in Regent College London, is seamless and efficient. Special thanks to Mr. Danish, for his expertise and guidance were invaluable in helping me to navigate complex challenges mainly the study gap of 20 years and achieve my goals. Highly recommended their services!',
    },
  ];

  // State to store the selected testimonial
  const [selectedTestimonial, setSelectedTestimonial] = useState(students[0]);
  const [direction, setDirection] = useState(1);
  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 300 : -300, // Start position for sliding in
      opacity: 0,
    }),
    center: {
      x: 0, // Center position
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? 300 : -300, // Exit position for sliding out
      opacity: 0,
    }),
  };

  // Function to handle student selection and trigger animation
  const handleStudentClick = (student, index) => {
    const newDirection = index > students.indexOf(selectedTestimonial) ? 1 : -1;
    setDirection(newDirection); // Direction for swipe (left or right)
    setSelectedTestimonial(student);
  };

  const TestimonialCard = ({ testimonialData }) => {
    return (
      <motion.div
        className="testimonial "
        variants={fadeIn('right', 1)} 

      >
        <span className="open quote">“</span>
        <div className="image">
          <div className="clip">

          </div>
          <img
            className="w-[130px] md:w-[150px] bg-darkpurple"
            src={testimonialData.image}
            alt={testimonialData.name}
          />
        </div>

        <p className='  pt-8  ' title={testimonialData.testimonial}>{testimonialData.testimonial.slice(0, 400)}</p>
        <div className="source ">
          <span className='name '>{testimonialData.name}</span>
        </div>



        <span className="close quote">”</span>
      </motion.div>
    );
  };



  return (
    <div className="bg-lightpink  font-poppins ">
      <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
       className="px-4 overflow-x-hidden  md:px-20 py-20 container mx-auto">
        <div className="flex flex-col space-y-6 pt-5 rounded-lg">
          <motion.div
           variants={fadeIn('down', 0.8)} 
           className="flex items-center justify-center">
            <CurvedUnderlineHeading>
              Testimonials
            </CurvedUnderlineHeading>

          </motion.div>
          <motion.h1
           variants={fadeIn('down', 1)} 
           className="text-center text-2xl text-shadyblack pt-4 md:pt-8 md:text-3xl font-mono  ">
            successs stories from our students
          </motion.h1>

          <div className="flex lg:flex-row flex-col-reverse justify-between py-10 gap-5 md:gap-0 items-center space-x-8">
            {/* Testimonial Card */}
            <TestimonialCard testimonialData={selectedTestimonial} />

            {/* Image Circles */}
            <motion.div
             variants={fadeIn('left', 1)} 
            className="relative bg-red  w-full lg:w-2/3 h-[380px] pr-10 md:pr-0 flex justify-center  items-center">
              {students.map((student, index) => {
                const angle = (index / students.length) * 360; // calculate angle for each student
                const radius = 120; // radius of the orbit
                const radius2 = 180; // radius of the orbit

                return (
                  <div
                    key={index}
                    className={`${selectedTestimonial.name === student.name
                        ? 'h-[110px] w-[110px] bg-primary-purple/80'
                        : ' h-[90px] w-[90px] bg-primary-purple/40 '
                      } cursor-pointer rounded-full overflow-hidden absolute hover:h-[110px] hover:w-[110px] hover:bg-primary-purple/80 ease-in-out duration-500`}
                    style={{
                      transform: `rotate(${angle}deg)  ${isMobile ? ` translate(${radius}px)` : `translate(${radius2}px)`} rotate(-${angle}deg)`, // rotate and translate to circular path
                    }}
                    onClick={() => handleStudentClick(student)}
                  >
                    <img
                      className={`${selectedTestimonial.name === student.name
                          ? 'border-2 border-darkpuple'
                          : ''
                        } rounded-full w-full h-full mt-0.5  object-cover`}
                      src={student.image}
                      alt={student.name}
                    />
                  </div>
                );
              })}
              {/* Planet at the center */}
              <div className="absolute  md:w-[250px] h-[100px] w-[100px] md:h-[250px] bgtestimonial rounded-full"></div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default StudentTestimonials;
