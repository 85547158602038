import React from 'react'
import Hero from '../Components/Home/Hero'
import Services from '../Components/Home/Services'
import Contact from './Contact'
import StudentTestemonials from '../Components/Home/StudentTestemonials'
import Consultation from '../Components/Home/Consultation'
import WhyUs from '../Components/Home/WhyUs'
import Contact2 from '../Components/Contact/Contact2'
import Faqs from '../Components/Faq/Faqs'

const Home = () => {
  return (
    <div className=' '>
    
      <Hero/>  
      <WhyUs/>
      <Services/>
      <Consultation/>
     <StudentTestemonials/>
    
   <Contact2/>
   <Faqs/>
    </div>
  )
}

export default Home
