
import React from 'react'
import "../Home/service.css"

import { FaHandshake, FaPeopleArrows, FaUniversity } from 'react-icons/fa'
import { FaPeopleGroup } from 'react-icons/fa6'
import { IoDiamond } from 'react-icons/io5'
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
import { NavLink } from 'react-router-dom'

const Services = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
  const data = [{
    Name: "University & College Admissions ",
    desc: "Click to see Details",
    icon: <FaUniversity size={64} />
  }, {
    Name: "Document Preparation & Review ",
    desc: "Click to see Details",
    icon: <FaPeopleArrows size={64} />

  }
    , {
    Name: "Visa Assistance",
    desc: "Click to see Details",
    icon: <IoDiamond size={64} />

  }
   

  ]

  const data2=[
     {
      Name: "Pre-departure Orientation",
      desc: "Click to see Details",
      icon: <FaHandshake size={64} />
  
    }
      , {
      Name: "Ongoing Support ",
      desc: "Click to see Details",
      icon: <FaPeopleGroup size={64} />
  
    }
  ]

  const Service = ({index, name, desc, image, icon }) => {
    return (
      <motion.div
      key={index}
      variants={fadeIn('down', index * 0.4)}
       className="container1 ">
        <div className="card bg-primary-purple/80 ">
          <div className='image space-y-2 bg-darkpuple rounded-lg py-4'>
            <p className='text-white' >{icon}</p>
            <h3 className='text-xs  text-white tracking-widest text-center py-2 text-nowrap font-semibold'>{name}</h3>
          </div>
  
          <div className="content text-white ">
  
           <NavLink to={'/services'}> <p className='text-[13px] '>{desc}</p></NavLink>
  
          </div>
        </div>
      </motion.div>
    )
  }
  return (
    <div className='bg-lightpinkl '>
      <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
       className="flex-col  mx-auto    text-shadyBlack container  py-10  font-poppins md:px-20  relative   ">
        <div className=''>
          <motion.div 
           variants={fadeIn('down', 0.8)} 
          className=' flex items-center justify-center pt-6'>
            <CurvedUnderlineHeading>
              Services
            </CurvedUnderlineHeading>
          </motion.div>
          <motion.div
           variants={fadeIn('down', 1)} 
           className='pt-12 text-sm text-center md:text-xl '> We offer high-quality and professional UK University Admission services to prospective students across the world. We maintain the highest level of integrity, transparency and fair practice with our valuable clients.</motion.div>

        </div>
        <div className='flex flex-col  w-full   py-8'>
          <motion.div
          variants={fadeIn('up', 1)} 
           className=' pt-8 px-10  md:grid-cols-2 xl:grid-cols-3 grid grid-cols-1 gap-4 w-full'>

            {
              data.map((service, index) => (
                <Service  index={index} name={service.Name} desc={service.desc} image={service.image} icon={service.icon} />
              ))
            }
            
          </motion.div>
          <motion.div
          variants={fadeIn('up', 1)} 
           className=' pt-8 px-10  md:grid-cols-2 xl:grid-cols-2 grid grid-cols-1 gap-4 w-full'>

{data2.map((service, index) => (
                <Service  index={index} name={service.Name} desc={service.desc} image={service.image} icon={service.icon} />
              ))

            }
            
          </motion.div>
          
        </div>




      </motion.div>
    </div>
  )
}

export default Services