import React from 'react'
import Hero from '../Components/MeetTheTeam/Hero'

import Consultation from '../Components/Home/Consultation'
import Contact from './Contact'
import WhyUs from '../Components/Home/WhyUs'
import Header from '../Components/MeetTheTeam/Header'
import CurvedUnderlineHeading from '../global/CurvedUnderlineHeading'
import Comitment from '../Components/MeetTheTeam/Comitment'
const Team = () => {
  return (
    <>
      <Header />
      <Hero />
      <Comitment/>
      <WhyUs />
      {/* <div className='py-10 px-4 md:px-20 space-y-10 '>
          <h1 className='text-5xl font-bold text-darkpuple tracking-wider'>Let’s Make It Happen
          </h1>
          <p className='text-lg leading-8 tracking-wide pl-2'>Are you ready to take the next step toward your academic dreams? Let First Group Consultant help you make that a reality.
          </p>
        </div> */}

      <Consultation />
      <Contact />
    </>
  )
}

export default Team