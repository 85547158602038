import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { motion } from 'framer-motion';
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaThreads } from 'react-icons/fa6';

const Contact2 = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  // State variables for form inputs
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [inquiry, setInquiry] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [qualificationLevel, setQualificationLevel] = useState('');

  const educationOptions = ['High School', 'Undergraduate or HND', 'Post Graduate', 'Other'];
  const qualificationOptions = ['IELTS, Pearson (PTE) or LangCert', 'MOI or Proficiency Letter', 'Not available yet', 'Other'];

  // Submit handler to log all the form data
  const handlePhoneChange = (value, countryData) => {
    setPhone(value); // Set the phone number
    setCountry(countryData.name); // Set the country name
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Check if the email is valid or not
    if (validateEmail(emailValue)) {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email address');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    setLoading(true);
    // Prepare form data object
    const formData = {
      firstName,
      lastName,
      inquiry,
      hle: educationLevel, // Highest Level of Education
      country,
      englishCertification: qualificationLevel, // English Certification
      phoneNumber: phone,
      message,
      userEmail: email
    };

    try {
      // Send the POST request to the API using Axios
      const response = await axios.post('https://api.firstgroupconsultant.com/v1/api/email/contact-us', formData);

      // Log the success response or handle it as needed
      console.log('Form submitted successfully:', response.data);
      toast.success('Form submitted successfully!');
      setLoading(false);
    } catch (error) {
      // Log the error or handle it as needed
      setLoading(false);
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        className='md:px-10 px-4 containter mx-auto overflow-x-hidden pt-10 pb-10 md:pt-28 text-shadyBlack flex-col md:flex-row flex'>

        <motion.div variants={fadeIn('right', 1)} className='gap-5 md:gap-0 flex-col md:w-2/5'>
          <div className="flex items-center justify-center">
            <CurvedUnderlineHeading>
              Contact Us
            </CurvedUnderlineHeading>
          </div>

          <div className='md:pt-8 md:pl-28'>
            {/* Form starts here */}
            <form className='space-y-5' onSubmit={handleSubmit}>
              {/* Name fields */}
              <div className='flex gap-5 w-full'>
                <div className='space-y-2 w-1/2'>
                  <label>First Name</label>
                  <input
                    className='w-full border rounded-md border-primary-purple p-2'
                    type='text'
                    required
                    placeholder='First Name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)} // Update state on change
                  />
                </div>
                <div className='space-y-2 w-1/2'>
                  <label>Last Name</label>
                  <input
                    className='w-full border rounded-md border-primary-purple p-2'
                    type='text'
                    required
                    placeholder='Last Name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)} // Update state on change
                  />
                </div>
              </div>

              

              {/* Email */}
              <div className='w-full space-y-2'>
                <label>Email</label>
                <input
                  className='w-full p-2 border rounded-md border-primary-purple'
                  type='text'
                  required
                  placeholder='Email'
                  value={email}
                  onChange={handleEmailChange}  // Validate email on change
                />
                {emailError && <p className="text-red-500">{emailError}</p>}  {/* Show error message */}
              </div>

              {/* Country/Region Phone Input */}
              <div className='w-full space-y-2'>
                <label>Phone Number</label>
                <PhoneInput
                  country={'gb'}  // Default country
                  value={phone}
                  onChange={handlePhoneChange}  // Extract phone number and country info
                  inputClass="w-full p-2 border rounded-md border-primary-purple"
                  containerClass="w-full"
                />
                {country && <p>Selected Country: {country}</p>} {/* Display country name */}
              </div>

              {/* Highest Level of Education Dropdown */}
              <div className='w-full space-y-2'>
                <label>Highest Level of Education</label>
                <select
                  className='w-full p-2 border rounded-md border-primary-purple'
                  value={educationLevel}
                  required
                  onChange={(e) => setEducationLevel(e.target.value)} // Update state on change
                >
                  <option value="" disabled>Select your education level</option>
                  {educationOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </select>
              </div>

              {/* Highest Level of Qualification Dropdown */}
              <div className='w-full space-y-2'>
                <label>English Certification</label>
                <select
                  className='w-full p-2 border rounded-md border-primary-purple'
                  required
                  value={qualificationLevel}
                  onChange={(e) => setQualificationLevel(e.target.value)} // Update state on change
                >
                  <option value="" disabled>Select your qualification level</option>
                  {qualificationOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {/* Inquiry About */}
              <div className='w-full space-y-2'>
                <label>Inquiry About</label>
                <input
                  className='w-full p-2 border rounded-md border-primary-purple'
                  type='text'
                  placeholder='Inquiry About'
                  value={inquiry}
                  onChange={(e) => setInquiry(e.target.value)} // Update state on change
                />
              </div>

              {/* Message */}
              <div className='w-full space-y-2'>
                <label>Message</label>
                <textarea
                  rows={4}
                  className='w-full p-2 border rounded-md border-primary-purple'
                  placeholder='Message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} // Update state on change
                />
              </div>

              {/* Submit Button */}
              <div className='flex justify-center'>
                <button
                  disabled={loading}
                  className={` ${loading?"animate-pulse":"animate-none"} px-2 tracking-widest cursor-pointer py-3 w-2/5 font-semibold text-xl rounded-md hover:bg-darkerPurple duration-300 bg-primary-purple flex justify-center`}
                  type='submit'
                >
                  <p className='text-white uppercase'>{loading  ?"Submitting":"Submit"}</p>
                </button>
              </div>
            </form>
            {/* Form ends here */}
          </div>
        </motion.div>

        {/* Right section */}
        <motion.div variants={fadeIn('left', 1)} className='md:w-3/5 pt-8 md:pt-0 flex justify-center items-center md:pl-24'>
          <div className='flex-col w-full space-y-10'>
            <div className='text-3xl font-semibold'>Ready to start your journey?</div>
            <p className='text-sm md:text-xl'>
              Email us at <span className='font-semibold tracking-wide text-darkerPurple'>info@firstgroupconsultant.com</span> or call us at <span className='font-semibold text-darkerPurple'>+44 7922 447784</span>.
            </p>

            {/* Social Media Icons */}
            <div className='py-4 space-y-6'>
              <h1 className='font-bold tracking-wider text-4xl'>Stay Connected</h1>
              <div className='flex justify-between w-1/3 pt-4 gap-2'>
                <FaFacebook size={44} className='text-shadyBlack cursor-pointer duration-300 hover:text-darkpurple' />
                <FaLinkedin size={44} className='text-shadyBlack cursor-pointer duration-300 hover:text-darkpurple' />
                <FaInstagram size={44} className='text-shadyBlack cursor-pointer duration-300 hover:text-darkpurple' />
                <FaThreads   size={44} className='text-shadyBlack cursor-pointer duration-300 hover:text-darkpurple' />
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
      <Toaster />
    </div>
  );
};

export default Contact2;
