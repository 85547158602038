import React from 'react'
import CurvedUnderlineHeading from '../../global/CurvedUnderlineHeading'
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
const Story = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2, 
      });
  return (
    <div className='bg-lightpinkl '>
       <motion.div 
         ref={ref}
         initial="hidden"
         animate={inView ? "show" : "hidden"}
         viewport={{ once: true }}
         variants={{
           hidden: {},
           show: {
             transition: {
               staggerChildren: 0.15,
             },
           },
         }}
       className="container overflow-x-hidden mx-auto px-4 lg:px-20 py-20 space-y-10">
          <motion.div
           variants={fadeIn('down', 1)} 
           className="flex items-center pb-8 justify-center">
            <CurvedUnderlineHeading>
              Our Story
            </CurvedUnderlineHeading>
          </motion.div>
          <motion.div 
          variants={fadeIn('up', 1)} 
          className=" py-8 flex gap-12 items-center justify-between">


            <div className="space-y-5">
              <p className="text-lg pt-4 leading-8">
                At <span className="font-semibold">First Group Consultant</span>,
                we, <span className="font-semibold">Danish</span> and his partner,
                are more than just advisors—we are co-founders committed to making a
                lasting impact on students' lives. Our journey began with a shared
                vision: to empower students to pursue their dreams in the UK.
              </p>
              <p className="text-lg pt-4 leading-8">
                With over five years of experience as education consultants at
                renowned universities in London, we’ve encountered countless
                students whose aspirations inspire us daily. We’ve navigated the
                challenges of university applications and have a thorough
                understanding of what institutions look for in prospective students.
                From securing scholarships to overcoming unique hurdles, our insider
                knowledge allows us to guide students with precision and care.{" "}
              </p>
              <p className="text-lg pt-4 leading-8">
                We realized that by collaborating with a variety of leading UK & EU
                institutions, rather than focusing on just one university, we could
                extend our reach and help more students achieve their dreams. This
                commitment is personal; we believe that a successful student leads
                to a successful family, and we’re dedicated to going the extra mile
                to help them reach their goals.
              </p>
              <p className="text-lg pt-4 leading-8">
                This partnership brings invaluable insight into how the academic
                system operates, especially for advanced degrees. Our experiences
                fuel our understanding of research and innovation, enabling us to
                prepare our students for both academic and real-world challenges.
              </p>
              <p className="text-lg pt-4 leading-8">
                We combine our expertise and passion to empower students, bridging
                the gap between ambition and achievement. Our core values of
                integrity, dedication, and innovation guide us as we strive to make
                the process of studying in the UK & EU as seamless and rewarding as
                possible.
              </p>
            </div>
          </motion.div>

        </motion.div>
    </div>
  )
}

export default Story
