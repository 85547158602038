import React from 'react'
import aboutpic from '../../assets/About/about1.png'
import sevice from "../../assets/Services/servicesbg.png"
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";
const Hero = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4, 
  });
  return (
    <div className='bg-primary-purple font-poppins  pt-8'>
      <motion.div 
      ref={ref}
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
      className='flex container   mx-auto flex-col min-h-[60vh] md:flex-row justify-between w-full lg:gap-12 xl:gap-28 items-center md:px-20  md:pt-20'>
        <motion.div
        variants={fadeIn('right', 1)} 
         className='px-4 w-full flex flex-col gap-4 '>
            <h1 className=' text-3xl md:text-7xl  font-medium'>Our Services </h1>
            <p>At <span className='font-semibold text-md'>First Group Consultant </span>, we offer a comprehensive range of services designed to support you throughout your journey to study in the UK & EU. Our commitment to empowering you means that we provide personalized guidance tailored to individual needs and aspirations.
            </p>
        </motion.div>
        <motion.div
        variants={fadeIn('left', 1)} 
         className=' flex relative w-full md:overflow-visible overflow-hidden max-w-[1000px]'>
            <div className='hidden xl:flex absolute bg-darkpuple  w-[250px] md:w-[350px] rounded-full shadow-2xl h-[250px] md:h-[350px] bottom-0 z-10 left-1/4 md:right-auto '></div>
            <img className=' z-20 ' loading='lazy' alt='?' src={sevice} />   
        </motion.div>
      </motion.div>
    </div>
  )
}

export default Hero
