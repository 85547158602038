import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const ChatBox = ({ onClose }) => {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappNumber = '447911123456'; // Replace with your WhatsApp number
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    onClose(); // Close the chat box after sending
  };
  const whatsappNumber = '447922447784';

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
  return (
    <div className="fixed bottom-20 font-poppins right-5 transition-transform transform translate-y-0 opacity-100 bg-white shadow-lg rounded-lg p-5 w-72 z-50 animate-fade-in">
    <div className='flex justify-between  items-center'><h3 className="font-semibold mb-2">Chat with us on WhatsApp</h3> <span onClick={onClose} className='font-bold text-red-500 cursor-pointer'>X</span></div>  
      <div className='flex flex-col gap-4' >
        <textarea
          rows="3"
          placeholder="Type your message..."
          className="w-full p-2 border rounded-md"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <a href={whatsappLink} target="_blank"
          rel="noopener noreferrer" className="mt-2 w-full my-2 text-center bg-green-500 text-white rounded-md p-2 hover:bg-green-600 transition">
          Send
        </a>
      </div>
     
    </div>
  );
};

export default ChatBox;
