import React from 'react'
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import { useInView } from "react-intersection-observer";

const Hero = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
  return (
    <div className='bg-primary-purple font-poppins'>
      <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
       className='mx-auto container py-20 md:px-20 px-4 items-center justify-center flex flex-col'>
        <motion.div 
        variants={fadeIn('down', 0.7)} 
        className='bg-white/50 backdrop-blur-lg mt-10 px-8 py-2 rounded-md  text-xl font-medim tracking-wider'>
          Home &gt;  Contact us
        </motion.div>
        <motion.h1
        variants={fadeIn('down', 0.9)}  className=' text-5xl  font-semibold py-10'>Contact Us</motion.h1>
        <motion.p
        variants={fadeIn('down', 1.2)} 
         className='text-start md:text-center'>At  <span className='font-semibold'>First Group Consultant</span>, we are here to support you every step of the way on your journey to studying in the UK. Whether you have questions about our services, need assistance with your application, or simply want to learn more about what we offer, we invite you to get in touch with us. </motion.p>
      </motion.div>
    </div>
  )
}

export default Hero
